<template>
  <div
    class="auth-wrapper auth-v1 px-2 bg-cover"
    :style="{
      'background-image': `url(${require('@/assets/images/pages/login/background-image.jpeg')})`,
    }"
  >
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-img
          :src="logoFull"
          alt="logo"
          class="w-full"
        />
        <b-card-title class="mt-1 mb-1 text-center text-[#535353]">
          Ground Water Monitoring System
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="email@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'forgot-password' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || waiting"
              class="mb-2"
              @click="login()"
            >
              <span v-if="waiting"><b-spinner small /> Signing in...</span>
              <span v-else>Sign in</span>
            </b-button>

            <small
              v-if="sessionExpired"
              class="text-danger text-center w-full block"
            >Your session has expired. Please sign in</small>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BImg,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { $themeConfig } from '@themeConfig'
import backgroundImage from '@/assets/images/pages/login/background-image.jpeg'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      backgroundImage,
      waiting: false,
      logoFull: require('@/assets/images/logo/logo-full-vertical-cropped-400.png'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    sessionExpired() {
      return !!this.$route.query.hasOwnProperty('sessionExpired')
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.setErrors({
        userEmail: ['This email is already taken'],
      })
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.waiting = true
          useJwt
            .login({
              username: this.userEmail,
              password: this.password,
            })
            .then(response => {
              const { user } = response.data
              useJwt.setToken(response.data.access_token)
              // useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(user))
              this.$ability.update(user.ability)
              this.waiting = false
              const userData = getUserData()
              this.$router.push(
                getHomeRouteForLoggedInUser(userData ? userData.ability : null),
              )
            })
            .catch(error => {
              this.waiting = false
              let message = 'There is an error. Please try again later'
              if (
                error.response.status == 403
                || error.response.status == 401
              ) {
                message = error.response.data.message
              }
              this.password = ''

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
