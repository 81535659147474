var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2 bg-cover",style:({
    'background-image': ("url(" + (require('@/assets/images/pages/login/background-image.jpeg')) + ")"),
  })},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-img',{staticClass:"w-full",attrs:{"src":_vm.logoFull,"alt":"logo"}}),_c('b-card-title',{staticClass:"mt-1 mb-1 text-center text-[#535353]"},[_vm._v(" Ground Water Monitoring System ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false : null,"placeholder":"email@example.com","autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('b-link',{attrs:{"to":{ name: 'forgot-password' }}},[_c('small',[_vm._v("Forgot Password?")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid || _vm.waiting},on:{"click":function($event){return _vm.login()}}},[(_vm.waiting)?_c('span',[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Signing in...")],1):_c('span',[_vm._v("Sign in")])]),(_vm.sessionExpired)?_c('small',{staticClass:"text-danger text-center w-full block"},[_vm._v("Your session has expired. Please sign in")]):_vm._e()],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }